import { Component } from "@angular/core";
import { take } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { AnvilorFormsAppStore } from "../../store/anvilor-forms-app-store.service";
import { RuiStore } from "@anvilor/ngx-rui";
import { FormControl } from "@angular/forms";
import { OnDestroy } from "@angular/core";
import { SubscriptionGroup } from "@anvilor/ngx-core-utils";
import { filterNullish } from "@anvilor/ngx-core-utils";

@Component({
  selector: "app-debug-mode-tools",
  templateUrl: "./debug-mode-tools.component.html",
  styleUrls: ["./debug-mode-tools.component.scss"],
})
export class DebugModeToolsComponent implements OnDestroy {
  isCollapsed = false;
  forceEnableSubmitFc = new FormControl(false, []);

  _subscriptions = new SubscriptionGroup();

  constructor(private app: AnvilorFormsAppStore, private rui: RuiStore) {
    this._subscriptions.push(
      this.forceEnableSubmitFc.valueChanges
        .pipe(
          filterNullish(),
          tap((force_enable_submit) => this.app.patchState({ force_enable_submit }))
        )
        .subscribe()
    );
  }

  logAppState() {
    this.app.state$.pipe(take(1), tap(console.log)).subscribe();
  }

  logRuiState() {
    this.rui.state$.pipe(take(1), tap(console.log)).subscribe();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribeAll();
  }
}
