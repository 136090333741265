<div class="debug-mode-tools density-compact" [class.collapsed]="isCollapsed">
  <div class="fxr-b-s">
    <div>Debug Tools</div>
    <div>
      <mat-icon (click)="isCollapsed = !isCollapsed">{{ isCollapsed ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
    </div>
  </div>
  <div *ngIf="!isCollapsed">
    <div class="fxr">
      <button mat-flat-button (click)="logAppState()" class="mg-sm">Log App State</button>
      <button mat-flat-button (click)="logRuiState()" class="mg-sm">Log RUI State</button>
    </div>
    <div>
      <mat-slide-toggle [formControl]="forceEnableSubmitFc">Force Submit Enable</mat-slide-toggle>
    </div>
  </div>
</div>
