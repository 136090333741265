<div
  class="fxr-b-c pd-xs attention-banner mat-elevation-z2"
  *ngIf="!!label"
  [style.background-color]="backgroundColor ?? '#FFCA28'"
  [style.height]="height"
  [class.closed]="!showBanner"
>
  <div></div>
  <div [innerHTML]="label"></div>
  <nvl-icon-action-button [config]="closeBannerButtonData" class="mg-right-sm"> </nvl-icon-action-button>
</div>
