import { QueryParamService } from "@anvilor/ngx-apps-core";
import { tap } from "rxjs";
import { map } from "rxjs/operators";
import { AnvilorFormsAppStore } from "../anvilor-forms-app-store.service";
import { AnvilorFormsAppStoreContext } from "../anvilor-forms-app-store.service";

const DEBUG_MODE_QPARAM = "debug";

export class AnvilorFormsAppDebugTools {
  private store: AnvilorFormsAppStore;
  private qparams: QueryParamService;

  constructor(ctx: AnvilorFormsAppStoreContext) {
    this.store = ctx.store;
    this.qparams = ctx.qparams;
  }

  initialize(): void {
    this.setDebugModeFromQueryParams();
  }

  private setDebugModeFromQueryParams(): void {
    this.store.subscribeTo(
      this.qparams.queryParams$.pipe(
        map((qp) => !!qp.get(DEBUG_MODE_QPARAM)),
        tap((is_debug_mode) => this.store.patchState({ is_debug_mode }))
      )
    );
  }
}
