import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { IconActionButtonConfig } from "@anvilor/ngx-apps-core";

@Component({
  selector: "app-attention-banner",
  templateUrl: "./attention-banner.component.html",
  styleUrls: ["./attention-banner.component.scss"],
})
export class AttentionBannerComponent {
  @Input() backgroundColor?: string | null;
  @Input() label!: string;
  @Input() height?: string;
  @Input() closeable?: boolean;

  showBanner = true;
  closeBannerButtonData: IconActionButtonConfig = {
    click: () => this.closeBanner(),
    maxHeight: "1em",
    icon: {
      source: "mat",
      value: "close",
    },
  };

  closeBanner() {
    this.showBanner = false;
  }
}
